import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API_SERVER } from "../Utils";



const TrackAwbNo = () => {
    const [inputValue, setInputValue] = useState('')
    const [details, setDetails] = useState({})
    const [detailsError, setDetailsError] = useState(false)
    const [packetDetails, setPacketDetails] = useState(true)
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamValue = queryParams.get('q') || '';

    async function AWBTrackingDetails(awbno) {
        const fetchData = await fetch(`${API_SERVER}/api/Web/Tracking`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ AwbNo: awbno })
        });
        const response = await fetchData.json();
        if (response.status === "SUCCESS") {
            setInputValue('')
            setDetailsError(false)
            setDetails(response) 
        } else if (response.status === "ERROR") {
            setDetails({}) 
            setInputValue('')
            setDetailsError(false)
            toast.error(response.message);
        } 
      
    }
    const downloadImage = () => {
        const byteCharacters = atob(details?.pod);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' }); 

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.jpg';
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        const currentInputValue = queryParams.get('q') || '';
        setInputValue(currentInputValue);
    }, [location.search]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue.trim() === '') {
            toast.error('Please enter AWB number')
            return;
        }            
        queryParams.set('q', inputValue);
        navigate({ search: queryParams.toString() });
        AWBTrackingDetails(inputValue)
    };

    useEffect(() => {
        AWBTrackingDetails(queryParamValue)
    }, [])

    
    return (
		<>
            <section className="tracking-app-banner tracking-page">
                <div className="container-fluid">
                    <div className="row"> 
                        <div className="col-lg-9">
                            {
                                Object.keys(details).length !== 0 && !detailsError ?
                                    (

                                        <div className="tracking-section">
                                            <div className="track-border">
                                                <div className="track-top">
                                                    <button>{details?.packetStatusDetail?.packetStatus}</button>
                                                    <ul>
                                                        <li><span className="bold-text">AWB No. :</span> {details?.awbNo}</li>
                                                        <li><span className="bold-text">Date :</span>{details?.date}</li>
                                                        <li><span className="bold-text">Bill No. :</span> {details?.billNo}</li>
                                                        <li><span className="bold-text">Forw No. :</span> {details?.forwardingNo}</li>
                                                        <li><span className="bold-text">Forw Name :</span> {details?.forwarder}</li>
                                                        {
                                                            details?.pod && 
                                                            <li><span className="bold-text">View POD :</span>    <button onClick={downloadImage} ><i style={{ cursor: "pointer" }} className="fa fa-eye"></i></button> </li>

                                                        }
                                                    </ul>
                                                    {/*<span className="toggle-point">*/}
                                                    {/*    <i className="fa fa-chevron-down"></i>*/}
                                                    {/*</span>*/}
                                                </div>
                                                <div id="track-data">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <div className="packet-status">
                                                                <h6 className="purple-text">Packet Status</h6>
                                                                <table className="table table-bordered table-striped">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Awb No </th>
                                                                            <td>{details?.packetStatusDetail?.awbNo}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Invoice No </th>
                                                                            <td>{details?.packetStatusDetail?.invoiceNo}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Booking Date </th>
                                                                            <td>{details?.packetStatusDetail?.bookingDate}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Rc Phone </th>
                                                                            <td>{details?.packetStatusDetail?.rcPhone}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Packet Status </th>
                                                                            <td>{details?.packetStatusDetail?.packetStatus}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Packet Status Date </th>
                                                                            <td>{details?.packetStatusDetail?.packetStatusDate}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>COD Cheque No </th>
                                                                            <td>{details?.packetStatusDetail?.codChequeNo}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Cheque Amount </th>
                                                                            <td>{details?.packetStatusDetail?.chequeAmount}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Hand Over Date </th>
                                                                            <td>{details?.packetStatusDetail?.handOverDate}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>From City </th>
                                                                            <td>{details?.packetStatusDetail?.fromCity}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>To City </th>
                                                                            <td>{details?.packetStatusDetail?.toCity}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>EDD </th>
                                                                            <td>{details?.packetStatusDetail?.eddDate}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <div className="packet-history">
                                                                <button className="btn header-button pincode" style={{ cursor: "pointer" }} onClick={() => setPacketDetails(!packetDetails)} >View Packet History</button>
                                                                <div style={{ display: packetDetails ? "none" : "block" }} className="white-box table-responsive">
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th title="Date">Date</th>
                                                                                <th title="Location">Location</th>
                                                                                <th title="Status Details">Status Details</th>
                                                                                <th title="Packet Status">Packet Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                details?.history?.map((ele, index) => (<tr key={index}>
                                                                                    <td>{ele?.date}</td>
                                                                                    <td>{ele?.location}</td>
                                                                                    <td>{ele?.statusDetails}</td>
                                                                                    <td>{ele?.packetStatus}</td>
                                                                                </tr>))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : <div  class="no-record">
                                    <div class="container">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-8 col-12">
                                                <div class="coming-content text-center">
                                                    <img src="images/no-record.png" alt="No Record" class="img-fluid" />
                                                    <h4 class="mt-5 text-center">No Record Found</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div style={{ marginTop: "20px", display:"none" }}> <span style={{ fontWeight: "bold", }}>Note</span> : The rates quoted are subject to City limits and pin code (as per Team Logistics) Please use Pin Code Locater to check for service</div>
                        </div>
                  
                        <div className="col-lg-3">
                            <div className='back_to_home_btn' style={{ marginBottom: "60px", textAlign:"end" }}>
                                <button type="button" onClick={() => navigate('/')} className="btn header-button pincode" id={'btnGet'}>
                                    <span> {'<-'} Back to Home</span>
                                </button>
                            </div>
                          
                            <div className="tracking-bx">
                                <div className="track-by-check"></div>
                                <div className="track-input-field">
                                    <div className="input-image">
                                        <img src="images/barcode.png" alt="Scan" title="Scan" />
                                    </div>
                                    <div className="fields">
                                        <input onChange={(e) => setInputValue(e.target.value)} value={inputValue} type="text" className="form-control" placeholder="Track AWB Numbers" />
                                        <span className="bar"></span>
                                    </div>
                                </div>
                                <div className="valid-awb-no">
                                    <span>For multiple queries use commas (.) eg:79034111122, 79034111041</span>
                                </div>
                                <div className="menu-button destop-track mt-3">
                                    <div onClick={(e) => handleSubmit(e)} className="button-box">
                                        <a className="header-button header-button3"><span>Click Here</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
		</>
    )
}

export default TrackAwbNo;