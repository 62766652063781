import { useNavigate } from 'react-router-dom';
import CurrentActivities from "./CurrentActivities";
import Services from "./Services";
import WhyChooseUs from "./WhyChooseUs";
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';


const Home = () => {
    const [awbnumber, setAWBNumber] = useState('')
    const navigate = useNavigate()
    const inputRef = useRef(null);

    const handleAWBalert = async () => {
        if (awbnumber.trim() === '') {
            toast.error('Please enter AWB number')
            inputRef.current.focus();
        } else {
            navigate(`/trackawbno?q=${encodeURIComponent(awbnumber)}`);
        }            
    }

    const focusedTrackShipment = useSelector((state) => state.commonData.focusedTrackShipment);
    const contactUsRef = useRef(null);

    useEffect(() => {
        if (focusedTrackShipment === 'trackShipment' && contactUsRef.current) {
            contactUsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            inputRef.current.focus();
        }
    }, [focusedTrackShipment]);
    
    return (
        <>
            <section className="home-banner">
                <section className="home-banner">
                    <div className="owl-carousel owl-theme" id="bannersliderhome">
                        <div className="item">
                            <img src="images/surfacer-freight.webp" alt="Air Freight" />
                        </div>
                        <div className="item">
                            <img src="images/rail-freight.webp" alt="Rail Freight" />
                        </div>
                        <div className="item">
                            <img src="images/air-freight.webp" alt="Surface Transport" />
                        </div>
                        <div className="item">
                            <img src="images/warehousing.webp" alt="Warehouse" />
                        </div>
                    </div>
                </section>

                <section ref={contactUsRef}
                    tabIndex="0" className="tracking-app-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="tracking-bx">
                                    <div className="track-by-check"></div>
                                    <div className="track-input-field">
                                        <div className="input-image">
                                            <img src="images/barcode.png" alt="Scan" title="Scan" />
                                        </div>
                                        <div className="fields">
                                            <input
                                                ref={inputRef}
                                                onChange={(e) => setAWBNumber(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                placeholder="Track  AWB Numbers"
                                            />
                                            <span className="bar"></span>
                                        </div>
                                    </div>
                                    <div className="valid-awb-no">
                                        <span>
                                            For multiple queries use commas (.) eg:79034111122,
                                            79034111041
                                        </span>
                                    </div>
                                    <div onClick={()=> handleAWBalert() } className="menu-button destop-track mt-3">
                                        <div className="button-box">
                                            <a className="header-button header-button3">
                                                <span>Click Here</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="tracking-image">
                                    <img
                                        src="images/track-image.jpg"
                                        className="img-fluid"
                                        alt="TL tracking"
                                        title="TL tracking"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <section className="about-us">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-image">
                                <img
                                    src="images/about-us.jpg"
                                    className="img-fluid"
                                    alt="About Us"
                                    title="About Us"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content">
                                <h2>
                                    Welcome to <img src="images/logo-final.png" />
                                </h2>
                                <p className="mt-3">
                                    Team Logistics is servicing the customers through
                                    Consolidation-Distribution-Logistics and all other
                                    transportation needs through its strategic alliances with the
                                    country&rsquo;s best service providers on a Single Window
                                    Management. From truckload to LTL, be it by Air or Surface, we
                                    have you covered with consolidation programs all over the
                                    country. Team Logistics provides the transportation services
                                    needed to lower your overall freight costs and reduce your
                                    internal administrative requirements. Team logistics manages
                                    all carrier selection and shipment tracking. Team logistics
                                    strives to bridge the gap between customer expectations and
                                    service provider capabilities. The company constantly adapts
                                    to the ever-changing requirements of the market thus enabling
                                    its customers to stay ahead of competition.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Services />
            <CurrentActivities />
            <WhyChooseUs />
        </>
    )
}

export default Home;