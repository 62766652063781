import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { API_SERVER } from '../Utils';


const PinCodeLocator = ({ pinLocator, setPinLocator }) => {

    const [inputValue, setInputValue] = useState('')
    const [loader, setLoader] = useState(false)
    const [jsonData, setJsonData] = useState({})

    const closeModal = () => {
        setPinLocator(false);
    };
    const handleChange = (e) => {
        setInputValue(e.target.value)
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            const fetchData = await fetch(`${API_SERVER}/api/Operation/PinCodeLocator`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({PinCode : inputValue})
            });
            const response = await fetchData.json();
            if (response.status === "SUCCESS") {
                toast.success(response.message);
                setJsonData(response)
                setLoader(false);
            } else if (response.status === "ERROR") {
                toast.error(response.message);
                setLoader(false);
            } 
            setLoader(false);
        } catch (err) {
            alert('Some Error Occurred! Please try again later.');
            setLoader(false);
        }
    };


    return (
        <>
            <div className={`modal fade pincode-modal ${pinLocator ? 'show' : ''}`} style={{ display: pinLocator ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="exampleModalLabel">Pincode Locator</h4>
                            <button type="button" className="btn-close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body">
                            <form className="d-flex" role="search">
                                <input onChange={handleChange} className="form-control me-2" type="search" placeholder="Enter Your Pincode"
                                    aria-label="Search" name="pincode" />
                                {/*<button onClick={(e) => handleSubmit(e)}  className=" header-button pincode">*/}
                                {/*    Search*/}
                                {/*</button>*/}

                                {loader ? <>
                                    <button type="button" className="header-button pincode " id={'btnGet'} >
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <span >Loading</span>
                                            <div class="custom-loader mt-2"></div>
                                        </div>
                                    </button>
                                </> : <>
                                        <button onClick={(e) => handleSubmit(e)} className=" header-button pincode">
                                            Search
                                        </button>
                                </>}
                            </form>

                            <p className="mt-4" style={{ color: 'red' }}>Note : ODA - Out of delivery/Pickup Area . Extra charges will be applicable.</p>
                            {
                                Object.keys(jsonData).length !== 0 && <div className="">
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>

                                                <th scope="row">PinCode</th>
                                                <td>{jsonData?.pinCode}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">City Name</th>
                                                <td>{jsonData?.city}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">State Name</th>
                                                <td>{jsonData?.state}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Location</th>
                                                <td>{jsonData?.location}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">ODA/Normal</th>
                                                <td>{jsonData.odaNormal}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                           
                        </div>
                    </div>
                </div>
            </div>
            {pinLocator && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

export default PinCodeLocator;
