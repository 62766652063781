import { useState } from "react";
import PinCodeLocator from "./PinCodeLocator";
import { useDispatch } from "react-redux";
import { setContactUsFocus, setServicesFocus, setTrackShipmentFocus } from "./commonSlice";

const Footer = () => {
    const [pinLocator, setPinLocator] = useState(false)
    const dispatch = useDispatch()

    const handleAdminClick = () => {
        window.open("http://cms.teamlogistics.in/", '_blank');
    }
    const handleClientClick = () => {
        window.open("http://client.teamlogistics.in/", '_blank');
    }

    return (
        <>            <footer>
            <div className="footer-main">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="ftr-contact">
                                <h5 className="ftr-heading">Head Office</h5>
                                <div className="btm-line maroon-bg my-3"></div>
                                <ul>
                                    <li>
                                        <i className="fas fa-map-marker-alt"></i>No 58/5, Puttanna
                                        Road Gandhi Bazaar, Basavanagudi, Bengaluru -560004,
                                        Karnataka
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-phone"></i>
                                        <a href="tel:+918150065002">+91 8150065002</a>
                                    </li>
                                    <li>
                                        <i className="fas fa-envelope"></i>
                                        <a href="mailto:sales@teamlogistics.in">
                                            sales@teamlogistics.in
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="ftr-social">
                                <ul>
                                    <li>
                                        <a
                                            href="/"
                                            data-toggle="tooltip"
                                            target="_blank"
                                            title=""
                                            data-bs-original-title="Follow on facebook"
                                        >
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/"
                                            data-toggle="tooltip"
                                            target="_blank"
                                            title=""
                                            data-bs-original-title="Follow on Twitter"
                                        >
                                            <i className="fa-brands fa-x-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/"
                                            data-toggle="tooltip"
                                            target="_blank"
                                            title=""
                                            data-bs-original-title="Follow on linkedin"
                                        >
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="ftr-links ftr-first">
                                <h5 className="ftr-heading">Quick Links</h5>
                                <div className="btm-line maroon-bg my-3"></div>
                                <ul>
                                    <li>
                                        <i className="fas fa-chevron-right"></i>{" "}
                                        <a href="/">Home</a>
                                    </li>
                                    <li style={{ cursor: "pointer" }} onClick={() => dispatch(setContactUsFocus('contactUs'))}>
                                        <i className="fas fa-chevron-right"></i>{" "}
                                        <a >Contact Us</a>
                                    </li>
                                    <li  onClick={() => setPinLocator(true) }>
                                        <i className="fas fa-chevron-right"></i>{" "}
                                        <a href="#">PinCode Locator</a>
                                    </li>
                                    <li style={{ cursor: "pointer" }} onClick={() => dispatch(setTrackShipmentFocus('trackShipment'))}>
                                        <i className="fas fa-chevron-right"></i>{" "}
                                        <a >Track Shipment</a>
                                    </li>
                                    <li onClick={() => handleAdminClick()} handleAdminClick>
                                        <i className="fas fa-chevron-right"></i>{" "}
                                        <a
                                            href="/"
                                            target="_blank"
                                            data-bs-toggle="modal"
                                            data-bs-target="#adminModal"
                                        >
                                            Admin Login
                                        </a>
                                    </li>
                                    <li onClick={() => handleClientClick()}>
                                        <i className="fas fa-chevron-right"></i>{" "}
                                        <a
                                            href="/"
                                            target="_blank"
                                            data-bs-toggle="modal"
                                            data-bs-target="#clientModal"
                                        >
                                            Client Login
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="ftr-links">
                                <h5 className="ftr-heading">Our Services</h5>
                                <div className="btm-line maroon-bg my-3"></div>
                                <ul>
                                    <li style={{ cursor: "pointer" }} onClick={() => dispatch(setServicesFocus('services'))}>
                                        <i className="fas fa-chevron-right"></i>{" "}
                                        <a >Air Freight</a>
                                    </li>
                                    <li style={{ cursor: "pointer" }} onClick={() => dispatch(setServicesFocus('services'))}>
                                        <i className="fas fa-chevron-right"></i>{" "}
                                        <a >Rail Freight</a>
                                    </li>
                                    <li style={{ cursor: "pointer" }} onClick={() => dispatch(setServicesFocus('services'))}>
                                        <i className="fas fa-chevron-right"></i>{" "}
                                        <a >Surface Transport</a>
                                    </li>
                                    <li style={{ cursor: "pointer" }} onClick={() => dispatch(setServicesFocus('services'))}>
                                        <i className="fas fa-chevron-right"></i>{" "}
                                        <a >Warehousing</a>
                                    </li>
                                    <li style={{ cursor: "pointer" }} onClick={() => dispatch(setServicesFocus('services'))}>
                                        <i className="fas fa-chevron-right"></i>{" "}
                                        <a >Duty Clearances</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
            <section className="footer_bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 text-center text-md-left">
                            <div className="copyroght">
                                <p>© 2024 Team Logistics. All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-center text-md-right">
                            <div className="designed_by">
                                <p>
                                    Powered By:{" "}
                                    <a
                                        href="https://www.sagarinfotech.com/"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        Sagar informatics Pvt. Ltd.
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                pinLocator && <PinCodeLocator pinLocator={pinLocator} setPinLocator={setPinLocator} />
            }

        </>
    )
}

export default Footer;