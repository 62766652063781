import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AutoCompleteBox, InputBox, SelectInputBox } from "../CommonFunction";
import { DropDownData, FetchAutoCompleteData, setFocus } from "../Custom";
import { API_SERVER } from "../Utils";

const GetRate = () => {
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const autoCompleteTimeoutRef = useRef(null);
    const [result, setResult] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingRate, setIsLoadingRate] = useState(false)
    const [modalFormData, setModalFormData] = useState({
        Name: "",
        EmailID: "",
        FullAddress: "",
        MobileNo: "",
        Contents: "",
        Value: "",
        ActWeight: "",
        Length: "",
        Width: "",
        Height: "",
        Mode: ''
    });
    const navigate = useNavigate();
    const [formData, setformData] = useState({
        DeliveryType: '',
        OriginCountry: '',
        DestinationCountry: '',
        OriginCity: '',
        DestinationCity: '',
        Weight: '',
        ProductType: '',
        Mode: ''
    })
   function convertTwoDecimalsOnValue(value) {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;
        return value.match(regex)[0];
    }
    function convertThreeDecimalsOnValue(value) {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,3})/s;
        return value.match(regex)[0];
    }
    const handleBlur = (e) => {
        const fieldName = e.target.name;
        const fieldValue = modalFormData[fieldName] || '';

        if (fieldValue !== '') {
            if (fieldName === "Value") {
                setModalFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: parseFloat(fieldValue).toFixed(2),
                }));
            } else {
                setModalFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: parseFloat(fieldValue).toFixed(3),
                }));
            }
        }
    };

    const handleClear = () => {
        setModalFormData({
            Name: "",
            EmailID: "",
            FullAddress: "",
            MobileNo: "",
            Contents: "",
            Value: "",
            ActWeight: "",
            Length: "",
            Width: "",
            Height: "",
            Mode: "",
        })
    }

    const handleSendEmail =async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const Token = process.env.REACT_APP_APITOKEN;
            const fetchData = await fetch(`${API_SERVER}/api/Web/SentMailForRate`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ Token:Token,...modalFormData })
            });
            const response = await fetchData.json();
            if (response.status === "SUCCESS") {
                handleClear();
                toast.success(response.message);
                setIsModalOpen(false)
                setIsLoading(false)
            } else if (response.status === "ERROR") {
                setFocus(response.focus)
                toast.error(response.message);
                setIsLoading(false)
            }
        } catch (err) {
            alert('Some Error Occurred! Please try again later.');
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setformData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(type, newValue, contextkey, contextKey2, '');
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            }, 300)
        }
    };
    let GetProductType = DropDownData('GetProductType');
    let GetMode = DropDownData('GetMode');

    const GetDetails = async () => {
        setIsLoadingRate(true)
        setResult([])
        const data1 =
            await fetch(`${API_SERVER}/api/web/GetRate`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify(formData)
            });

        const data = await data1.json()

        if (data.status === "SUCCESS") {
            setResult(data.getRates)
            setIsLoadingRate(false)
        } else if (data.status === "ERROR") {
            toast.error(data.message, {
                position: "top-center"
            });
            setIsLoadingRate(false)
            setResult([])
        }
        setIsLoadingRate(false)
    };
    const bannedCommodities = [
        "Currency, Cheques, Bullion, Payment Cards, Traveler Cheques, Stamps",
        "Dangerous goods",
        "Precious & Semi-Precious Items",
        "Commodities banned by Law at any given time without prior notice",
        "Any shipment which would be likely to cause damage, or delay to equipment, personnel or other shipments",
        "Liquids & Semi-liquids",
        "Any item whose carriage is prohibited by any law, statute or regulations of the country of origin or destination, or of any country through which the shipment may transit",
        "Foodstuffs/Perishable (Food and beverages requiring refrigeration or other environmental control)",
        "Live animals & plants"
    ];
    const shippingInfo = [
        "Goods & service tax calculated as per government norms.",
        "Max weight for medicine shipments is limited up to 1 Kg and/or 3 months’ supply. Prescription and GST included in purchase receipts are mandatory.",
        "The rates quoted above are based on the weights specified. Shipping weight will be calculated as the higher of actual weight of the shipment and the volumetric weight.",
        "Weights will be rounded up to the nearest .5kg for shipments under 20kgs and will be rounded up to the nearest 1 kg for shipments over 20 kgs.",
        "Any item whose carriage is prohibited by any law, statute or regulations of the country of origin or destination, or of any country through which the shipment may transit.",
        "Final charges will depend upon the actual weights measured at the time of tendering the shipment."
    ];

    useEffect(() => {
        setFocus('txtDeliveryType');
    }, [])
    useEffect(() => {
        setFocus('txtName');
    }, [isModalOpen])
    return (
        <><section className="get-rate-section">
            <div className="container">
                <div className='back_to_home_btn'>

                    <button type="button" onClick={() => navigate('/')} className="btn header-button pincode" id={'btnGet'}>
                        <span> {'<-'} Back to Home</span>
                    </button>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <div className="get-rate-form-section">
                            <div class="rate-form-title">
                                <h2>Get your Rates <span style={{ color: "#ed1c24" }} >get a pickup from the comfort of your location</span></h2>
                                <div class="get-rate-points mt-3 mb-4">
                                    <div class="row">
                                        <div class="col-lg-7 col-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-12 get-rate-point">
                                                    <img src="images/check-mark.svg" alt="" width="20px" class="me-2" />
                                                    <p>Way to go with Multiple Payment Options</p>
                                                </div>
                                                <div class="col-lg-6 col-12 get-rate-point">
                                                    <img src="images/check-mark.svg" alt="" width="20px" class="me-2" />
                                                    <p>Flexibility of Real Time Tracking</p>
                                                </div>
                                                <div class="col-lg-6 col-12 get-rate-point">
                                                    <img src="images/check-mark.svg" alt="" width="20px" class="me-2" />
                                                    <p>Hassle-Free Pickup and Delivery</p>
                                                </div>
                                                <div class="col-lg-6 col-12 get-rate-point">
                                                    <img src="images/check-mark.svg" alt="" width="20px" class="me-2" />
                                                    <p>Reliable, Safer, Faster Delivery</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form className="rate-form">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-6 col-md-12 col-12 mb-4">
                                        <div className="input-group">
                                            <select
                                                id={'txtDeliveryType'}
                                                className="form-select"
                                                aria-label="Default select example"
                                                required=""
                                                value={formData.DeliveryType}
                                                onChange={(e) => {
                                                    setformData({ ...formData, DeliveryType: e.target.value, OriginCity: '', DestinationCity: '', OriginCountry: '', DestinationCountry: '' })
                                                }}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        if (formData.DeliveryType === 'Domestic') {
                                                            setFocus('txtOrginiCity');
                                                        }
                                                        else {
                                                            setFocus('txtDestinationCountry');
                                                        }
                                                    }
                                                }}
                                            >
                                                <option value=''>Select Delivery Type</option>
                                                <option value='Domestic'>Domestic</option>
                                                <option value='International'>International</option>
                                            </select>
                                            <div className="input-group-text">
                                                <i className="fa fa-globe" />
                                            </div>
                                        </div>
                                    </div>
                                    {formData.DeliveryType === "International" && (
                                        <>
                                            <div className='col-xl-4 col-lg-6 col-md-12 col-12 mb-4'>
                                                <div className="input-group">
                                                    <AutoCompleteBox
                                                        id={'txtOrginCountry'}
                                                        placeholder="Origin Country"
                                                        options={autoCompleteOptions}
                                                        setOptions={setAutoCompleteOptions}
                                                        required
                                                        inputValue={formData.OriginCountry ? formData.OriginCountry : ''}
                                                        onInputChange={(event, newinputvalue) => {
                                                            handleAutoCompleteChange(event, newinputvalue, 'OriginCountry', 'GetCountry')
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter') {
                                                                setFocus('txtDestinationCountry');
                                                            }
                                                        }}
                                                    />
                                                    <div className="input-group-text international">
                                                        <i className="fa fa-flag" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-12 col-12 mb-4">
                                                <div className="input-group">
                                                    <AutoCompleteBox
                                                        id={'txtDestinationCountry'}
                                                        placeholder="Destination Country"
                                                        options={autoCompleteOptions}
                                                        setOptions={setAutoCompleteOptions}
                                                        required
                                                        inputValue={formData.DestinationCountry ? formData.DestinationCountry : ''}
                                                        onInputChange={(event, newinputvalue) => {
                                                            handleAutoCompleteChange(event, newinputvalue, 'DestinationCountry', 'GetCountry')
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter') {
                                                                setFocus('txtWeight');
                                                            }
                                                        }}
                                                    />
                                                    <div className="input-group-text international">
                                                        <i className="fa fa-flag" />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {formData.DeliveryType === "Domestic" && (
                                        <>
                                            <div className="col-xl-4 col-lg-6 col-md-12 col-12 mb-4">
                                                <div className="input-group">
                                                    <AutoCompleteBox
                                                        id={'txtOriginCity'}
                                                        placeholder="Origin City"
                                                        options={autoCompleteOptions}
                                                        setOptions={setAutoCompleteOptions}
                                                        required
                                                        inputValue={formData.OriginCity ? formData.OriginCity : ''}
                                                        onInputChange={(event, newinputvalue) => {
                                                            handleAutoCompleteChange(event, newinputvalue, 'OriginCity', 'GetCity')
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter') {
                                                                setFocus('txtDestinationCity');
                                                            }
                                                        }}
                                                    />
                                                    <div className="input-group-text">
                                                        <i className="fa fa-city" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-12 col-12 mb-4">
                                                <div className="input-group">
                                                    <AutoCompleteBox
                                                        id={'txtDestinationCity'}
                                                        placeholder="Destination City"
                                                        options={autoCompleteOptions}
                                                        setOptions={setAutoCompleteOptions}
                                                        required
                                                        inputValue={formData.DestinationCity ? formData.DestinationCity : ''}
                                                        onInputChange={(event, newinputvalue) => {
                                                            handleAutoCompleteChange(event, newinputvalue, 'DestinationCity', 'GetCity')
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter') {
                                                                setFocus('txtWeight');
                                                            }
                                                        }}
                                                    />
                                                    <div className="input-group-text">
                                                        <i className="fa fa-city" />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="col-xl-4 col-lg-6 col-md-12 col-12 mb-4">
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                id={'txtWeight'}
                                                type="text"
                                                placeholder="Weight"
                                                required=""
                                                value={formData.Weight}
                                                onChange={(e) => {
                                                    setformData({ ...formData, Weight: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus('txtProductType');
                                                    }
                                                }}
                                                autoComplete="off"
                                            />
                                            <div className="input-group-text">Kg</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-12 col-12 mb-4">
                                        <div className="input-group">
                                            <SelectInputBox
                                                className="form-select"
                                                id={'txtProductType'}
                                                placeholder={"Product Type"}
                                                value={formData.ProductType}
                                                onChange={(e) => {
                                                    setformData({ ...formData, ProductType: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus('txtProductType');
                                                    }
                                                }}
                                            >
                                                <option key='please0' value=''>Select Product Type</option>
                                                {GetProductType?.data.dataList?.map((item, index) =>
                                                    <option key={item.values + index} value={item.values}>{item.values}</option>)}
                                            </SelectInputBox>
                                            <div className="input-group-text">
                                                <i className="fa fa-boxes-packing" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-12 col-12 mb-4">
                                        <div className="input-group">
                                            <SelectInputBox
                                                className="form-select"
                                                id={'txtMode'}
                                                placeholder="Mode"
                                                value={formData?.Mode || ''}
                                                onChange={(e) => {
                                                    setformData({ ...formData, Mode: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus('btnGet');
                                                    }
                                                }}
                                            >
                                                <option key='please0' value=''>Select Mode</option>
                                                {GetMode?.data.dataList?.map((item, index) =>
                                                    <option key={item.values + index} value={item.values}>{item.values}</option>)}
                                            </SelectInputBox>
                                            <div className="input-group-text">
                                                <i className="fa fa-truck-fast" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-12">
                                        {isLoadingRate ? <>
                                            <button type="button" className="btn header-button pincode " id={'btnGet'} >
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <span >Loading</span>
                                                    <div class="custom-loader mt-2"></div>
                                                </div>
                                            </button>
                                        </> : <>
                                            <button type="button" className="btn header-button pincode" onClick={() => GetDetails()} id={'btnGet'}>
                                                <span>Get Rate</span>
                                            </button>
                                        </>}
                                        
                                    </div>
                                </div>
                            </form>
                        </div>
                        {result.length !== 0 &&
                            <div className="rate-table mt-5">
                                <p className="mt-3 mb-4">
                                    We've found below services for your given parcel details:
                                </p>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Mode</th>
                                                <th scope="col">Service By</th>
                                                <th scope="col">Rate</th>
                                                <th scope="col">Taxes</th>
                                                <th scope="col">Total</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {result?.map((item, index) => {
                                                // Ensure item is defined and has valid properties
                                                const Mode = item?.mode || '';
                                                const serviceType = item?.serviceType || '';
                                                const rate = Number(item?.rate).toFixed(2)
                                                const taxes = Number(item?.taxes).toFixed(2)
                                                const total = Number(item?.total).toFixed(2)

                                                return (
                                                    <tr key={index}>
                                                        <td>{Mode}</td>
                                                        <td>{serviceType}</td>
                                                        <td>{rate}</td>
                                                        <td>{taxes}</td>
                                                        <td>{total}</td>
                                                        <td>
                                                            <button
                                                                className="btn btn-sm btn-primary"
                                                                onClick={() => {
                                                                    setModalFormData({ ...modalFormData, Mode }); 
                                                                    setIsModalOpen(true);
                                                                }}
                                                            >
                                                                Book
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {/*Booking Modal Starts Here*/}
                        {
                            <div className={`modal fade pincode-modal ${isModalOpen ? 'show' : ''}`} style={{ display: isModalOpen ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" id="exampleModalLabel">Booking Details</h4>
                                            <button type="button" className="btn-close" onClick={() => setIsModalOpen(false)}></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <InputBox
                                                    id="txtName"
                                                    label="Name"
                                                    placeholder="Name"
                                                    name="Name"
                                                    required={true}
                                                    maxLength="30"
                                                    value={modalFormData.Name}
                                                    onChange={(e) => setModalFormData({ ...modalFormData, Name: e.target.value })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            setFocus('txtEmail');
                                                        }
                                                    } }
                                                />
                                                <InputBox
                                                    id="txtEmail"
                                                    label="Email"
                                                    placeholder="Email"
                                                    name="EmailID"
                                                    required={true}
                                                    maxLength="100"
                                                    value={modalFormData.EmailID}
                                                    onChange={(e) => setModalFormData({ ...modalFormData, EmailID: e.target.value })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            setFocus('txtMobileNo');
                                                        }
                                                    }}
                                                />
                                                <InputBox
                                                    id="txtMobileNo"
                                                    label="Mobile No"
                                                    placeholder="Mobile No"
                                                    name="MobileNo"
                                                    required={true}
                                                    maxLength="15"
                                                    value={modalFormData.MobileNo}
                                                    onChange={(e) => setModalFormData({ ...modalFormData, MobileNo: e.target.value.replace(/[^0-9]/, '') })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            setFocus('txtContents');
                                                        }
                                                    }}
                                                />
                                                <div className="fields col-lg-3 col-md-3 col-sm-6">
                                                    <label className="form-label">Contents<span className="text-danger">*</span></label>
                                                    <div className="form-group">
                                                        <textarea
                                                            id="txtContents"
                                                            required={true}
                                                            maxLength="500"
                                                            className="form-control"
                                                            value={modalFormData.Contents}
                                                            name="Contents"
                                                            placeholder="Contents"
                                                            onChange={(e) => setModalFormData({ ...modalFormData, Contents: e.target.value })}
                                                            rows={1}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    setFocus('txtValue');
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <InputBox
                                                    id="txtValue"
                                                    divclassname="col-lg-3 col-md-3 col-sm-6 pe-0"
                                                    label="Value"
                                                    placeholder="0"
                                                    required={true}
                                                   // onBlur={handleBlur}
                                                    value={modalFormData.Value }
                                                    name="Value"
                                                    onChange={(e) => setModalFormData({ ...modalFormData, Value: convertTwoDecimalsOnValue(e.target.value) })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            setFocus('txtActWeight');
                                                        }
                                                    }}
                                                />
                                                <InputBox
                                                    id="txtActWeight"
                                                    divclassname="col-lg-3 col-md-3 col-sm-6 pe-0"
                                                    label="Act Weight"
                                                   // onBlur={handleBlur}
                                                    required={true}
                                                    placeholder="0.000"
                                                    name="ActWeight"
                                                    value={modalFormData.ActWeight}
                                                    onChange={(e) => setModalFormData({ ...modalFormData, ActWeight: convertThreeDecimalsOnValue(e.target.value) })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            setFocus('txtAddress');
                                                        }
                                                    }}
                                                />
                                                <div className="fields col-lg-6 col-md-6 col-sm-6">
                                                    <label className="form-label">Full Address<span className="text-danger">*</span></label>
                                                    <div className="form-group">
                                                        <textarea
                                                            id="txtAddress"
                                                            required={true}
                                                            className="form-control"
                                                            value={modalFormData.FullAddress}
                                                            name="FullAddress"
                                                            placeholder="Full Address"
                                                            onChange={(e) => setModalFormData({ ...modalFormData, FullAddress: e.target.value })}
                                                            rows={1}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    setFocus('txtLength');
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="fields col-lg-6 col-md-6 col-sm-6">
                                                    <label className="form-label">Measurement (CM)</label>
                                                    <div className="table-responsive">
                                                        <table className="table table-striped mymodaltable">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th><label className="form-label">Length</label></th>
                                                                    <th><label className="form-label">Width</label></th>
                                                                    <th><label className="form-label">Height</label></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="mymodaltablebody">
                                                                <tr>
                                                                    <td>
                                                                        <input
                                                                            id="txtLength"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="0.000"
                                                                            name="Length"
                                                                            required={true}
                                                                            maxLength='12'
                                                                            value={modalFormData.Length}
                                                                            onBlur={handleBlur}
                                                                            onChange={(e) => setModalFormData({ ...modalFormData, Length: convertThreeDecimalsOnValue(e.target.value) })}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    setFocus('txtWidth');
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input className="form-control"
                                                                            id="txtWidth"
                                                                            name="Width"
                                                                            placeholder="0.000"
                                                                            onBlur={handleBlur}
                                                                            required={true}
                                                                            maxLength='12'
                                                                            value={modalFormData.Width}
                                                                            onChange={(e) => setModalFormData({ ...modalFormData, Width: convertThreeDecimalsOnValue(e.target.value) })}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    setFocus('txtHeight');
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input className="form-control"
                                                                            id="txtHeight"
                                                                            name="Height"
                                                                            placeholder="0.000"
                                                                            onBlur={handleBlur}
                                                                            required={true}
                                                                            maxLength='12'
                                                                            value={modalFormData.Height}
                                                                            onChange={(e) => setModalFormData({ ...modalFormData, Height: convertThreeDecimalsOnValue(e.target.value) })}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    setFocus('txtSubmit');
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-3 " style={{ paddingTop:"4rem" }}>
                                                    {isLoading ? <>
                                                        <button className="btn btn-sm btn-success me-4" >
                                                            <div className='d-flex justify-content-center align-items-center'> <span >Loading</span>
                                                                <div class="custom-loader mt-2"></div>
                                                            </div>
                                                        </button>
                                                    </> : <>
                                                            <button className="btn btn-sm btn-success me-4" id="txtSubmit" onClick={handleSendEmail}>Submit</button>
                                                    </>}
                                                    <button className="btn btn-sm btn-danger" id="txtSubmit" onClick={handleClear }>Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/*Booking Modal End Here*/}
                        <div style={{ marginTop: "20px" }}> <span style={{ fontWeight: "bold", }}>Note</span> : The rates quoted are subject to City limits and pin code (as per Team Logistics) Please use Pin Code Locater to check for service</div>
                        <div className='mt-5'>
                            <h4>Following commodities are banned</h4>
                            <ul className='banned-list'>
                                {bannedCommodities?.map((commodity, index) => (
                                    <li key={index}>{commodity}</li>
                                ))}
                            </ul>
                        </div>
                        <div className='mt-4'>
                            <h4>T&C Apply</h4>
                            <ul className='banned-list'>
                                {shippingInfo?.map((commodity, index) => (
                                    <li key={index}>{commodity}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
          
        </>

    )
}

export default GetRate;
