import { useCallback, useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PopupWithIcon from "./PopupWithIcon";


export const AutoCompleteBox = (props) => {
    const { divclassname = "col-lg-4 col-md-6 col-12 mb-4", divstyle, name, id, maxLength = "100", label, required, options, InfoDetails, setOptions, placeholder,
        inputValue, onKeyPress, onInputChange, onChange, onKeyDown, value, variant, disabled, error } = props
    return (<>

        <Autocomplete
            name={name}
            id={id}
            noOptionsText={'No Options'}
            inputValue={inputValue}
            onInputChange={onInputChange}
            onChange={onChange}
            freeSolo
            filterOptions={(x) => x}
            options={options ? options : []}
            onClose={(event, reason) => {
                if (setOptions) {
                    setOptions([]);
                }
            }}
            disabled={disabled}
            autoHighlight={true}
         
            selectOnFocus={true}
            renderInput={(params) => <TextField
                {...params}
                onInput={(e) => {
                    const { value } = e.target;
                    if (value.length > maxLength) {
                        e.target.value = value.slice(0, maxLength);
                    }
                }}
                variant={variant}
                sx={{
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "##C4C4C4"
                    }
                }}
                onKeyPress={onKeyPress}
                onKeyDown={onKeyDown}
                autoComplete="off"
                value={value}
                placeholder={placeholder}
            />}
        />
        {error && <div className="error-msg">{error}</div>}
    </>)
}
export const SelectInputBox = (props) => {
    const { divclassname = 'col-lg-4 col-md-6 col-12 mb-4"', inputclassname = '', onKeyDown, divstyle, inputstyle, inputrefs, id, label = '', value = '', onChange, children, required = false } = props
    return (
            <select
                id={id}
                className={`form-select ${inputclassname}`}
                style={inputstyle}
                onKeyDown={onKeyDown}
                value={value}
                onChange={onChange}
                title={required ? 'Please select an item in the list.' : ''}
                autoComplete="off"
                {...props}
            >
                {children}
            </select>
    )
}

export const InputBox = (props) => {

    const { divclassname = "col-lg-3 col-md-3 col-sm-6 pe-0", name, onChange, validator, Note, error, label, onClick, id, divstyle, maxLength = "50", minLength = "3", value, required, inputstyle, onKeyDown, onKeyUp, onFocus, onBlur, pattern, type = "text", inputclassname, placeholder, disabled, InfoDetails } = props

    return (
        <div className={`fields ${divclassname}`} style={divstyle}>
            {label !== "" && label && <label className="form-label text-truncate" >{(label)}{required && <span className="text-danger">*</span>} </label>
            }
            <input
                name={name}
                className={`form-control ${inputclassname} `}
                id={id}
                maxLength={maxLength}
                minLength={minLength}
                style={value !== "" && validator && !validator.test(value) ? { ...inputstyle, borderColor: "red" } : inputstyle}
                disabled={disabled}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={onClick}
                pattern={pattern}
                type={type ? type : "text"}
                value={value}
                placeholder={placeholder}
                autoComplete="off"
            />
            {Note && <span className={validator ? !validator.test(value) ? "note_class_error" : "note_class_success" : "note_class_error"}>{Note}</span>}
            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}


export const FileUploadChild = (props) => {
    const { label = '', message = '', types = [], children } = props;

    return (
        <>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="#0658C2"></path><path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="#0658C2"></path><path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z"
                fill="#0658C2"></path></svg>
            <div className="sc-eqUAAy file-children ">
                {(message.trim() === '.' || message.trim() === '') ?
                    <span className="sc-fqkvVR" style={{ fontSize: "12px" }}><span style={{ fontSize: "12px" }}>{`Upload ${label}`}</span>{label === "Category Image" || label === "Sub Category Image" || label === "Banner Image" ? "" : "or drop a file right here"} </span> :
                    <span className="sc-fqkvVR" style={{ fontSize: "12px" }}><span className=' text-success'>{`${message}`}</span >Uploaded</span>
                }
                {(message.trim() !== '.' || message.trim() !== '') &&
                    <span style={{ fontSize: "12px" }} title={`types: ${types.join()}`} className="file-types text-info">{types.join()}</span>
                }
                {children}
            </div>
        </>
    );
}