

import { useNavigate } from 'react-router-dom';

const CommingSoon = () => {

    const navigate = useNavigate()

    return (
        <div class="coming-soon">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="coming-content">
                            <img src="images/coming-soon.png" alt="coming-soon" class="img-fluid" />
                                <h4 class="mt-5 text-center">Instant parcel rate codes for seamless shipping quotes.</h4>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: "center", marginTop:"20px" }}>

                    <button type="button" onClick={() => navigate('/')} className="btn header-button pincode" id={'btnGet'}>
                        <span> {'<-'} Back to Home</span>
                    </button>
                </div>
            </div>
        </div>
    )
}


export default CommingSoon;