import { useEffect, useRef, useState } from "react";
import { API_SERVER } from "../Utils";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";


const WhyChooseUs = () => {
    const inputRef = useRef([]);
    const [quoteFormData, setQuoteFormData] = useState({
        name: '',
        contactNo: '',
        email: '',
        message: '',
    })
    const [loader, setLoader] = useState(false)

    const focusedElementId = useSelector((state) => state.commonData.focusedElementId);
    const contactUsRef = useRef(null);

    useEffect(() => {
        if (focusedElementId === 'contactUs' && contactUsRef.current) {
            contactUsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            contactUsRef.current.focus();
        }
    }, [focusedElementId]);

    const handleSubmitQuote = async (e) => {
        e.preventDefault();  
        try {
            setLoader(true); 
            const fetchData = await fetch(`${API_SERVER}/api/Web/Contact`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(quoteFormData) 
            });
            const response = await fetchData.json();
            if (response.status === "SUCCESS") {
                toast.success(response.message);
                setQuoteFormData({
                    name: '',
                    contactNo: '',
                    email: '',
                    message: '',
                })
            } else if (response.status === "ERROR") {
                toast.error(response.message);
            }
            setLoader(false);
        } catch (err) {
            alert('Some Error Occurred! Please try again later.'); 
            setLoader(false); 
        }
    };


    return (
        <>
          
            <section className="why-choose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="web-heading text-center">
                                <h3>Centralised Customer Service - <span> 8150065002</span></h3>
                            </div>
                            <div className="web-text text-center mt-3 mb-4">
                                <span>customerservice@teamlogistics.in</span>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Ahmedabad - Sarangpur</h4>
                                    <a href="tel:+919998939582">+91 9998939582</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Bangalore - Gandhi Bazar</h4>
                                    <a href="tel:+918150065002">+91 8150065002</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Kolkata - MG Road</h4>
                                    <a href="tel:+919831515194">+91 9831515194</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Chennai - Meenambakkam</h4>
                                    <a href="tel:+918946061790">+91 8946061790</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Coimbatore - PN Palayam</h4>
                                    <a href="tel:+919940514996">+91 9940514996</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Delhi - Rangapuri</h4>
                                    <a href="tel:+919911510075">+91 9911510075</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Hyderabad - Begumpet</h4>
                                    <a href="tel:+919573073298">+91 9573073298</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Mumbai - Andheri East</h4>
                                    <a href="tel:+917738236687">+91 7738236687</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Pune - Tingre Nagar</h4>
                                    <a href="tel:+919940514996">+91 9940514996</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Guwahati - Paltan Bazar</h4>
                                    <a href="tel:+919706485425">+91 9706485425</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Jaipur - Santhi Colony</h4>
                                    <a href="tel:+919602525142">+91 9602525142</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="contact-number">
                                    <h4>Cochin - MG Road</h4>
                                    <a href="tel:+919567497526">+91 9567497526</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="why-choose country-city">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <div className="web-heading text-center">
                                <h3>International<span> Locations</span></h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <img src="images/usa.png" alt="USA Flag" className="img-fluid" />
                                </div>
                                <div className="contact-number">
                                    <h4>USA - New York</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <img src="images/singapore.png" alt="Singapore Flag" className="img-fluid"/>
                                </div>
                                <div className="contact-number">
                                    <h4>Singapore - Singapore</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <img src="images/united-kingdom.png" alt="UK Flag" className="img-fluid" />
                                </div>
                                <div className="contact-number">
                                    <h4>UK - London</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <img src="images/austria.png" alt="Spain Flag" className="img-fluid" />
                                </div>
                                <div className="contact-number">
                                    <h4>Austria - Vienna</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <img src="images/france.png" alt="France Flag" className="img-fluid"  />
                                </div>
                                <div className="contact-number">
                                    <h4>France - Paris</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <img src="images/germany.png" alt="Germany Flag" className="img-fluid" />
                                </div>
                                <div className="contact-number">
                                    <h4>Germany - Hesse</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <img src="images/switzerland.png" alt="Switzerland Flag" className="img-fluid" />
                                </div>
                                <div className="contact-number">
                                    <h4>Switzerland - Interlaken</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="industry-sec  mt-3">
                                <div className="industry-icon">
                                    <img src="images/united-arab-emirates.png" alt="UAE Flag" className="img-fluid" />
                                </div>
                                <div className="contact-number">
                                    <h4>UAE - Qatar</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section ref={contactUsRef}
                tabIndex="0" className="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="faq-question">
                                <div className="web-heading">
                                    <h3>
                                        Why Choose<span> Us?</span>
                                    </h3>
                                </div>
                                <div className="choose-pointer mt-3">
                                    <ul>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>Single Source Shipping</span>
                                        </li>
                                        <p>
                                            Enjoy the convenience of handling all your shipping needs
                                            through one reliable provider, simplifying your logistics
                                            process.
                                        </p>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>Cost Effective Shipping Solutions</span>
                                        </li>
                                        <p>
                                            Benefit from our competitive pricing, designed to reduce
                                            your shipping costs without compromising on service
                                            quality.
                                        </p>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>Time Bound Services</span>
                                        </li>
                                        <p>
                                            Rely on our commitment to timely deliveries, ensuring your
                                            goods reach their destination on schedule, every time.
                                        </p>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>Fast & Secure</span>
                                        </li>
                                        <p>
                                            Count on our rapid and reliable services to keep your
                                            supply chain moving smoothly and securely.
                                        </p>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>Customized Solution</span>
                                        </li>
                                        <p>
                                            Get tailored logistics solutions that meet your specific
                                            business requirements, enhancing efficiency and
                                            satisfaction.
                                        </p>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>Easy & Safe Transportation</span>
                                        </li>
                                        <p>
                                            Experience hassle-free, secure transportation services
                                            that protect your goods throughout the journey.
                                        </p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div  className="col-lg-6">
                            <div className="contact-form" id="contact-us">
                                <div className="web-heading">
                                    <h3>
                                        Quick<span> Quotes</span>
                                    </h3>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-6">
                                        <div className="fields">
                                            <div className="form-group">
                                                <label>
                                                    Name<span>*</span>
                                                </label>
                                                <input
                                                    id="textName"
                                                    ref={(ref) => {
                                                        inputRef.current["textName"] = ref;
                                                    }}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    value={quoteFormData.name}
                                                    onChange={(e) => {
                                                        setQuoteFormData({ ...quoteFormData, name: e.target.value })
                                                    }}
                                                    maxLength={100}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            inputRef.current["textPhoneNo"].focus();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="fields">
                                            <div className="form-group">
                                                <label>
                                                    Phone No.<span>*</span>
                                                </label>
                                                <input
                                                    id="textPhoneNo"
                                                    ref={(ref) => {
                                                        inputRef.current["textPhoneNo"] = ref;
                                                    }}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Phone No."
                                                    value={quoteFormData.contactNo}
                                                    onChange={(e) => {
                                                        setQuoteFormData({ ...quoteFormData, contactNo: e.target.value.replace(/[^0-9]/g, '') })
                                                    }}
                                                    maxLength={10}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            inputRef.current["textEmail"].focus();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="fields">
                                            <div className="form-group">
                                                <label>
                                                    Email<span>*</span>
                                                </label>
                                                <input
                                                    id="textEmail"
                                                    ref={(ref) => {
                                                        inputRef.current["textEmail"] = ref;
                                                    }}
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    value={quoteFormData.email}
                                                    onChange={(e) => {
                                                        setQuoteFormData({ ...quoteFormData, email: e.target.value })
                                                    }}
                                                    maxLength={100}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            inputRef.current["textMessage"].focus();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="fields">
                                            <div className="form-group">
                                                <label>Message</label>
                                                <textarea
                                                    id="textMessage"
                                                    ref={(ref) => {
                                                        inputRef.current["textMessage"] = ref;
                                                    }}
                                                    className="form-control"
                                                    value={quoteFormData.message}
                                                    onChange={(e) => {
                                                        setQuoteFormData({ ...quoteFormData, message: e.target.value })
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            //inputRef.current["textMessage"].focus();
                                                            handleSubmitQuote()
                                                        }
                                                    }}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="menu-button mt-3">
                                            <div className="button-box" //onClick={handleSubmitQuote}
                                            >
                                                <button onClick={handleSubmitQuote} disabled={loader} style={{ padding: "10px 20px", }} className="header-button header-button3">
                                                    <span>{loader ? "Sending..." : "Get a Quote"}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section></>
    )
}

export default WhyChooseUs;