import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./components/Footer";
import GetRate from './components/GetRate';
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import TrackAwbNo from './components/TrackAwbNo';
import "./custom.css";
import CommingSoon from './components/CommingSoon';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { resetContactUsFocus, resetServicesFocus, resetTrackShipmentFocus } from './components/commonSlice';
import CouriorPickUpRequest from './components/CouriorPickUpRequest';

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleScroll = () => {
            dispatch(resetContactUsFocus());
            dispatch(resetTrackShipmentFocus());
            dispatch(resetServicesFocus());
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch]);
    return (
        <div className="App">
            <ToastContainer position="top-right" newestOnTop={true} />
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/trackawbno" element={<TrackAwbNo />} />
                <Route path="/getRate" element={<GetRate />} />
                <Route path="/CouriorPickUpRequest" element={<CouriorPickUpRequest />} />
                <Route path="/commingSoon" element={<CommingSoon />} />
            </Routes>
            <Footer />

        </div>
    );
}

export default App;
