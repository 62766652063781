import { useState } from "react";
import PinCodeLocator from "./PinCodeLocator";
import { useNavigate } from 'react-router-dom';




const Navbar = () => {
    const [pinLocator, setPinLocator] = useState(false)
    const navigate = useNavigate()

    const handleAdminClick = () => {
        window.open("http://cms.teamlogistics.in/", '_blank');
    }
    const handleClientClick = () => {
        window.open("http://client.teamlogistics.in/", '_blank');
    }



    return (
        <>
            <section className="top-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-10 col-sm-9 col-8">
                        <div className="top-left-header">
                            <ul>
                                <li>
                                    <i className="fas fa-map-marker-alt"></i>
                                    <div>
                                        <p>
                                            No 58/5, Puttanna Road Gandhi Bazaar, Basavanagudi,
                                            Bengaluru -560004, Karnataka
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <i className="fa-solid fa-phone"></i>
                                    <div>
                                        <a
                                            href="tel:918150065002"
                                            data-toggle="tooltip"
                                            data-bs-original-title="Call Us"
                                        >
                                            +91 8150065002
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <i className="fa-solid fa-envelope"></i>
                                    <div>
                                        <a
                                            href="mailto:sales@teamlogistics.in"
                                            data-toggle="tooltip"
                                            data-bs-original-title="Mail Us"
                                        >
                                            sales@teamlogistics.in
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-3 col-4">
                        <div className="top-right-header">
                            <ul>
                                <li>
                                    <a
                                        href="#"
                                        target="_blank"
                                        data-toggle="tooltip"
                                        aria-label="Facebook"
                                        data-bs-original-title="Facebook"
                                    >
                                        <i className="fa-brands fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        target="_blank"
                                        data-toggle="tooltip"
                                        aria-label="Twitter-X"
                                        data-bs-original-title="Twitter-X"
                                    >
                                        <i className="fa-brands fa-x-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        target="_blank"
                                        data-toggle="tooltip"
                                        aria-label="Linked-In"
                                        data-bs-original-title="Linked-In"
                                    >
                                        <i className="fa-brands fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            <section className="menu sticky-top menu-nav" id="head-menu">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-3 col-sm-4 col-6">
                            <div className="logo">
                                <a className="navbar-brand" href="/">
                                    <img
                                        src="images/logo-final.png"
                                        alt="Team Logistics"
                                        title="Team Logistics"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-9 col-sm-8 col-6">
                            <div className="menu-button">
                                <div onClick={() => setPinLocator(true)} className="button-box">
                                    <a className="header-button header-button2">
                                        <span>PinCode Locator</span>
                                    </a>
                                </div>
                                <div  className="button-box">
                                    <a href="/getrate" className="header-button">
                                        <span>Get Rate</span>
                                    </a>
                                </div>

                                <div onClick={() => {
                                    navigate('/CouriorPickUpRequest')
                                }} className="button-box">
                                    <a className="header-button header-button2">
                                        <span>Courier-Pickup-Request</span>
                                    </a>
                                </div>

                                <div onClick={() => handleAdminClick()} className="button-box">
                                    <a
                                        className="header-button header-button3"
                                    >
                                        <span>Admin Login</span>
                                    </a>
                                </div>
                                <div onClick={() => handleClientClick()} className="button-box">
                                    <a
                                       
                                        className="header-button header-button2"
                                    >
                                        <span>Client Login</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

       

            {
                pinLocator && <PinCodeLocator pinLocator={pinLocator} setPinLocator={setPinLocator} />
            }


        </>
    )
}

export default Navbar;