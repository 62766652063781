import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const Services = () => {
    const focusedElementId = useSelector((state) => state.commonData.focusedservices);
    const contactUsRef = useRef(null);

    useEffect(() => {
        if (focusedElementId === 'services' && contactUsRef.current) {
            contactUsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            contactUsRef.current.focus();
        }
    }, [focusedElementId]);
    return (
        <>        <section ref={contactUsRef}
            tabIndex="0" className="our-services" id="ourservices">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="sec-heading text-center">
                            <h3>
                                Discover Our <span>Services</span>
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-4 col-sm-4">
                        <div className="service-box mt-5">
                            <div className="service-image">
                                <img src="images/air-freight.jpg" alt="Air Freight" />
                            </div>
                            <div className="service-content">
                                <div>
                                    <h4>Air Freight</h4>
                                    <p>Air Courier & Cargo Domestic,International</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4">
                        <div className="service-box mt-5">
                            <div className="service-image">
                                <img src="images/train-freight.jpg" alt="Rail Freight" />
                            </div>
                            <div className="service-content">
                                <div>
                                    <h4>Rail Freight</h4>
                                    <p>
                                        Being a client-centric organization, we are involved in
                                        offering Rail Freight Forwarding Service.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4">
                        <div className="service-box mt-5">
                            <div className="service-image">
                                <img
                                    src="images/Surface-Transport.jpg"
                                    alt="Surface Transport"
                                />
                            </div>
                            <div className="service-content">
                                <div>
                                    <h4>Surface Transport</h4>
                                    <p>
                                        Pan India Service for Cargo shipment Express Mode &ndash; Part
                                        Load &ndash; Full Load by LCV* Trucks * Rail
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4">
                        <div className="service-box mt-5">
                            <div className="service-image">
                                <img src="images/warehouse.jpg" alt="Warehousing" />
                            </div>
                            <div className="service-content">
                                <div>
                                    <h4>Warehousing</h4>
                                    <p>
                                        Offer both dedicated and shared facilities From
                                        procurement of raw materials to distribution of finished
                                        products
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4">
                        <div className="service-box mt-5">
                            <div className="service-image">
                                <img src="images/Duty-Clearances.jpg" alt="Duty Clearances" />
                            </div>
                            <div className="service-content">
                                <div>
                                    <h4>Duty Clearances</h4>
                                    <p>Inbound Duty Payments and Customs Brokerage</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            <section className="our-advantage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="advantage-box">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="advantage-image">
                                            <img
                                                src="images/our-advantage.jpg"
                                                className="img-fluid"
                                                alt="Service Highlights"
                                                title="Service Highlights"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="sec-heading">
                                            <h3>
                                                Service <span>Highlights</span>
                                            </h3>
                                        </div>
                                        <div className="advantage-content mt-3">
                                            <ul>
                                                <li>
                                                    <i className="fa-solid fa-circle-check"></i>
                                                    <span>AWB of the service provider</span>
                                                </li>
                                                <li>
                                                    <i className="fa-solid fa-circle-check"></i>
                                                    <span>Single window payment</span>
                                                </li>
                                                <li>
                                                    <i className="fa-solid fa-circle-check"></i>
                                                    <span>Proof of deliveries Online</span>
                                                </li>
                                                <li>
                                                    <i className="fa-solid fa-circle-check"></i>
                                                    <span>Online MIS designed to suit your needs</span>
                                                </li>
                                                <li>
                                                    <i className="fa-solid fa-circle-check"></i>
                                                    <span>Damage claims assist</span>
                                                </li>
                                                <li>
                                                    <i className="fa-solid fa-circle-check"></i>
                                                    <span>Shipment tracking on single site</span>
                                                </li>
                                                <li>
                                                    <i className="fa-solid fa-circle-check"></i>
                                                    <span>Outbound LTL and TL</span>
                                                </li>
                                                <li>
                                                    <i className="fa-solid fa-circle-check"></i>
                                                    <span>Expedited delivery service</span>
                                                </li>
                                                <li>
                                                    <i className="fa-solid fa-circle-check"></i>
                                                    <span>COD / DOD collection</span>
                                                </li>
                                                <li>
                                                    <i className="fa-solid fa-circle-check"></i>
                                                    <span>High Value Shipments </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section></>
    )
}

export default Services;