import { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AutoCompleteBox, SelectInputBox } from '../CommonFunction';
import { DropDownData, FetchAutoCompleteData, setFocus } from '../Custom';
import { API_SERVER } from '../Utils';
const CouriorPickUpRequest = () => {
    const navigate = useNavigate()
    const autoCompleteTimeoutRef = useRef(null);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [addFilesList, setaddFilesList] = useState([]);
    const [incomeModalShow, setincomeModalShow] = useState(false)
    const handleModalShowClose = () => setincomeModalShow(false);
    const [extension, setExtension] = useState('')
    const [basefile, setBaseFile] = useState('')
    const handleOpen = async (base46, extensionName) => {
        await setExtension(extensionName)
        await setBaseFile(base46)
        await setincomeModalShow(true)

    };
    const [formData, setFormData] = useState({
        "pickupPinCode": '',
        "location": '',
        "contactPerson": '',
        "mobileNo": '',
        "email": '',
        "pickUpAddress": '',
        "destinationAddress": '',
        "destinationPinCode": '',
        "Service": '',
        "value":'',
        "date": '',
        "time": '',
        "specialInstruction": '',
        "addShipmentDetailList": [
            {
                "addShipmentDetailListID": '',
                "weightUnit": '',
                "noOfPackages": '',
                "length": '',
                "breath": '',
                "hight": '',
            }
        ],
    })

    function convertTwoDecimalsOnValue(value) {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;
        return value.match(regex)[0];
    }
    function convertThreeDecimalsOnValue(value) {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,3})/s;
        return value.match(regex)[0];
    }
    
    function convertToContactNumber(value) {
        return value.replace(/[^0-9+-]/g, '');
    }

    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(type, newValue, contextkey, contextKey2, '');
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            }, 300)
        }
    };

    let GetMode = DropDownData('GetMode');
  
    const handleAdd = () => {
        let list = [...formData.addShipmentDetailList];
        list = [...list, {
            "addShipmentDetailListID": '',
            "weightUnit": '',
            "noOfPackages": '',
            "length": '',
            "breath": '',
            "hight": '',
        }];
        setFormData({ ...formData, addShipmentDetailList: list })
    };


    const handleDelete = (index) => {
        if (formData.addShipmentDetailList?.length <= 1) {
            return
        } else {
            let list = [...formData.addShipmentDetailList];
            list.splice(index, 1);
            setFormData({ ...formData, addShipmentDetailList: list });
        }
    };

    const handleClear = () => {
        setFormData({
            "pickupPinCode": '',
            "location": '',
            "contactPerson": '',
            "mobileNo": '',
            "email": '',
            "pickUpAddress": '',
            "destinationAddress": '',
            "destinationPinCode": '',
            "Service": '',
            "value": '',
            "date": '',
            "time": '',
            "specialInstruction": '',
            "addShipmentDetailList": [
                {
                    "addShipmentDetailListID": '',
                    "weightUnit": '',
                    "noOfPackages": '',
                    "length": '',
                    "breath": '',
                    "hight": '',
                }
            ],
        })
        setFocus('txtPickupPincode');
        setaddFilesList([])
        let a = document.querySelectorAll('.sc-fqkvVR');
        if (a.length > 0) {
            a[0].style.fontSize = '12px';
            a[0].innerHTML = `<span style="text-decoration: underline;">Upload</span> or drop a file right here`;
        }

    }

   



    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const data={
            ...formData,
            addFilesList: addFilesList?.map((item) => ({
                File: item.Base64Image,
                FileName: item.ImageName,
                FileExt: item.ImageExt
            }))
        }


       setIsLoading(true)
        try {
            const Token = process.env.REACT_APP_APITOKEN;
            const fetchData = await fetch(`${API_SERVER}/api/Web/PickupBooking`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ Token: Token, ...data })
            });
            const response = await fetchData.json();
            if (response.status === "SUCCESS") {
                toast.success(response.message);
                handleClear()
             
            } else if (response.status === "ERROR") {
                setFocus(response.focus)
                toast.error(response.message);
                setIsLoading(false)
            }
        } catch (err) {
            alert('Some Error Occurred! Please try again later.');
           setIsLoading(false)
        }
       setIsLoading(false)
    }
    const fileTypes = 'jpg,jpeg,png,pdf'

    const handleFileUploader = (files) => {
        const newFiles = Array.from(files);

        newFiles.forEach((file) => {
            const extArr = file.name.split('.');
            const fileExtension = extArr[extArr.length - 1].toLowerCase();
            if (fileTypes.split(',').includes(fileExtension)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const img = reader.result;
                    const base64Image = img.split(',')[1];
                    const newPdfData = {
                        Base64Image: base64Image,
                        ImageExt: fileExtension,
                        FileSize: (file.size / 1024).toFixed(2),
                        ImageName: extArr.slice(0, -1).join('.'),
                    };
                    const isUploaded = addFilesList.some(item => item.Base64Image === base64Image);

                    if (!isUploaded) {
                        setaddFilesList(prevFiles => [...prevFiles, newPdfData]);
                    } else {
                   
                        toast.error(`${newPdfData.ImageName} File already exists. Please upload a different File.`);
                    }
                };
            } else {
                
                toast.error('Invalid file type. Please select jpeg, png, jpg, or pdf files.');
            }
        });
    };

    const base64ToBlob = (base64, mime) => {
        const byteChars = atob(base64);
        const byteArrays = [];
        for (let offset = 0; offset < byteChars.length; offset += 512) {
            const slice = byteChars.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: mime });
    };

    const generatePdfUrl = (base64) => {
        const blob = base64ToBlob(base64, 'application/pdf');
        return URL.createObjectURL(blob);
    };

  


    return (
        <><section className="get-rate-section" style={{ padding:"45px 0" }}>
            <div className="container">
                <div className='back_to_home_btn'>

                    <button type="button" onClick={() => navigate('/')} className="btn header-button pincode" id={'btnGet'}>
                        <span> {'<-'} Back to Home</span>
                    </button>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <div className="get-rate-form-section">
                            <form className="rate-form">
                                <div className="row">
                                    <h5>Pickup Details</h5>
                                    <div className='col-xl-3 col-lg-6 col-md-12 col-12 mb-4'>
                                        <label style={{ padding: '2px 14px' }}>Pick up  Pincode <span style={{ color: "red" }}> *</span></label>
                                        <div className="input-group muiwidthfull">
                                            <AutoCompleteBox
                                                id={'txtPickupPincode'}
                                                placeholder="Pick up  Pincode"
                                                options={autoCompleteOptions}
                                                 setOptions={setAutoCompleteOptions}
                                                required
                                                inputValue={formData.pickupPinCode ? formData.pickupPinCode : ''}
                                                onInputChange={(event, newinputvalue) => {
                                                    handleAutoCompleteChange(event, newinputvalue, 'pickupPinCode', 'GetPincode')
                                                }}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        setFocus('txtLocation');
                                                    }
                                                }}
                                            />

                                        </div>
                                    </div>


                                    <div className='col-xl-3 col-lg-6 col-md-12 col-12 mb-4'>
                                        <label style={{ padding: '2px 14px' }}> Location <span style={{ color: "red" }}> *</span></label>
                                        <div className="input-group muiwidthfull">
                                            <AutoCompleteBox
                                                id={'txtLocation'}
                                                placeholder="Location"
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                required
                                                inputValue={formData.location ? formData.location : ''}
                                                onInputChange={(event, newinputvalue) => {
                                                    handleAutoCompleteChange(event, newinputvalue, 'location', 'GetCity')
                                                }}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        setFocus('txtConatctPerson');
                                                    }
                                                }}
                                            />

                                        </div>
                                    </div>


                                 

                                    <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
                                        <label style={{ padding: '2px 14px' }}>contact Person <span style={{ color: "red" }}> *</span></label>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                id={'txtConatctPerson'}
                                                type="text"
                                                placeholder="Contact Person"
                                                required=""
                                                value={formData.contactPerson}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, contactPerson: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus('txtMobileNo');
                                                    }
                                                }}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
                                        <label style={{ padding: '2px 14px' }}>Mobile No <span style={{ color: "red" }}> *</span></label>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                id={'txtMobileNo'}
                                                type="text"
                                                placeholder="Mobile No"
                                                required=""
                                                maxLength='10'
                                                value={formData.mobileNo}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, mobileNo: convertToContactNumber(e.target.value) })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus('txtEmail');
                                                    }
                                                }}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                   
                                        <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
                                            <label style={{ padding: '2px 14px' }}>Email<span style={{ color: "red" }}> *</span></label>
                                            <div className="input-group">
                                                <input
                                                    className="form-control"
                                                    id={'txtEmail'}
                                                    type="email"
                                                    placeholder="Email"
                                                    required=""
                                                    value={formData.email}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, email: e.target.value })
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setFocus('txtPickupAddress');
                                                        }
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                 
                                    <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
                                        <label style={{ padding: '2px 14px' }}>Pick up Address<span style={{ color: "red" }}> *</span></label>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                id={'txtPickupAddress'}
                                                type="text"
                                                placeholder="Pick up Address "
                                                required=""
                                                value={formData.pickUpAddress}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, pickUpAddress: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus('txtDestinationAddress');
                                                    }
                                                }}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
                                        <label style={{ padding: '2px 14px' }}>Destination Address<span style={{ color: "red" }}> *</span></label>

                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                id={'txtDestinationAddress'}
                                                type="text"
                                                placeholder="Destination Address"
                                                required=""
                                                value={formData.destinationAddress}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, destinationAddress: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus('txtDestinationPincode');
                                                    }
                                                }}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
                                        <label style={{ padding: '2px 14px' }}>Destination Pincode<span style={{ color: "red" }}> *</span></label>

                                        <div className="input-group muiwidthfull">
                                            <AutoCompleteBox
                                                id={'txtDestinationPincode'}
                                                placeholder="Destination Pincode"
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                required
                                                inputValue={formData.destinationPinCode ? formData.destinationPinCode : ''}
                                                onInputChange={(event, newinputvalue) => {
                                                    handleAutoCompleteChange(event, newinputvalue, 'destinationPinCode', 'GetPincode')
                                                }}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        setFocus('txtService');
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>


                                </div>

                               



                                <div className="row">

                                    <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
                                      
                                        <label style={{ padding: '2px 14px' }}>Service<span style={{ color: "red" }}> *</span> </label>
                                        <div className="input-group">

                                            <SelectInputBox
                                                classname="form-select"
                                                id={'txtService'}
                                                placeholder="  Select Service"
                                                value={formData.Service}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        setFocus('txtValue');
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, Service: e.target.value });
                                                }}
                                            >
                                                <option key='please0' value=''>--Select Service--</option>
                                                {GetMode?.data.dataList?.map((item, index) =>
                                                    <option key={item.values + index} value={item.values}>{item.values}</option>)}
                                            </SelectInputBox>


                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
                                        <label style={{ padding: '2px 14px' }}>Value<span style={{ color: "red" }}> *</span></label>

                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                id={'txtValue'}
                                                type="text"
                                                placeholder="Value"
                                                required=""
                                                value={formData.value}
                                                onBlur={(e) => {
                                                  
                                                    const valueWithTwoDecimals = parseFloat(e.target.value).toFixed(2);
                                                    setFormData({ ...formData, value: valueWithTwoDecimals });
                                                }}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, value: convertTwoDecimalsOnValue(e.target.value) })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus('txtDate');
                                                    }
                                                }}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
                                        <label style={{ padding: '2px 14px' }}>Date<span style={{ color: "red" }}> *</span></label>

                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                id={'txtDate'}
                                                type="date"
                                                onChange={(e) => {
                                                    setFormData({ ...formData, date: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus('txtTime');
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
                                        <label style={{ padding: '2px 14px' }}>Time<span style={{ color: "red" }}> *</span></label>

                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                id={'txtTime'}
                                                type="time"
                                                value={formData.time}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, time: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus('txtweightUnit0');
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <h5>Shipment Details</h5>
                                    </div>
                                 
                                    {
                                       
                                        <div className='card-body p-2'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped mb-0">
                                                    <thead>
                                                        <tr className='table_heading tableheader'>

                                                           
                                                            <th style={{ padding: '5px 4px', width: "0rem" }}>Del</th>
                                                            <th style={{ padding: '5px 5px' }}>Weight  <span style={{ color: "red" }}> *</span></th>
                                                            <th style={{ padding: '5px 5px' }}>No Of Package <span style={{ color: "red" }}> *</span></th>
                                                            <th style={{ padding: '5px 5px' }}>Length </th>
                                                            <th style={{ padding: '5px 5px' }}>Breath </th>
                                                            <th style={{ padding: '5px 5px' }}>Height </th>
                                                            <th style={{ padding: '5px 5px' }}>Add</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {formData?.addShipmentDetailList?.map((item, index) =>
                                                            <tr key={`packageDetailsList${index + 1}`} className="TableTrhover" >
                                                                <td className='' style={{ minWidth: "10px" }} >
                                                                    {<span style={{ marginLeft: "-2px" }} >
                                                                        <i className="fa-regular fa-trash-can text-danger me-3 text-center "
                                                                            onClick={() => handleDelete(index)}
                                                                            style={{ marginLeft: "10px", marginTop: "10px", paddingRight: "0px" }}
                                                                        />
                                                                    </span>}
                                                                </td>
                                                                <td >
                                                                    <div className="input-group">
                                                       
                                                                     
                                                                        <input
                                                                            placeholder='Weight'
                                                                            className="form-control"
                                                                            value={item.weightUnit}
                                                                            maxLength='10'
                                                                            id={`txtweightUnit${index}`}
                                                                            onBlur={(e) => {
                                                                                let list = [...formData.addShipmentDetailList];
                                                                                let weightUnit = parseFloat(item.weightUnit || 0).toFixed(3);
                                                                                list[index] = { ...list[index], weightUnit: weightUnit };
                                                                                setFormData({ ...formData, addShipmentDetailList: list });
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    setFocus(`txtnoOfPackages${index}`);
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                let weightUnit = e.target.value;
                                                                                weightUnit = convertThreeDecimalsOnValue(weightUnit);
                                                                                let list = [...formData.addShipmentDetailList];
                                                                                list[index] = { ...list[index], weightUnit: weightUnit, }
                                                                                setFormData({ ...formData, addShipmentDetailList: list });
                                                                            }}
                                                                            onFocus={(e) => e.target.select()}
                                                                        />



                                                                    </div>
                                                                </td>



                                                                <td >
                                                                    <div className="input-group">
                                                                        <input
                                                                            placeholder='No Of Package'
                                                                            className="form-control"
                                                                            value={item.noOfPackages}
                                                                            maxLength='5'

                                                                            id={`txtnoOfPackages${index}`}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    setFocus(`txtLength${index}`);
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                let noOfPackages = e.target.value;
                                                                                noOfPackages = convertToContactNumber(noOfPackages);
                                                                                let list = [...formData.addShipmentDetailList];
                                                                                list[index] = { ...list[index], noOfPackages: noOfPackages, }
                                                                                setFormData({ ...formData, addShipmentDetailList: list });
                                                                            }}
                                                                            onFocus={(e) => e.target.select()}
                                                                        />
                                                                    </div>
                                                                </td>

                                                                <td >
                                                                    <div className="input-group">
                                                                        <input
                                                                            placeholder='Length'
                                                                            className="form-control"
                                                                            value={item.length}
                                                                            maxLength='10'
                                                                            id={`txtLength${index}`}
                                                                            onBlur={(e) => {
                                                                                let list = [...formData.addShipmentDetailList];
                                                                                let length = parseFloat(item.length || 0).toFixed(3);
                                                                                list[index] = { ...list[index], length: length };
                                                                                setFormData({ ...formData, addShipmentDetailList: list });
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    setFocus(`txtBreadth${index}`);
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                let length = e.target.value;
                                                                                length = convertThreeDecimalsOnValue(length);
                                                                                let list = [...formData.addShipmentDetailList];
                                                                                list[index] = { ...list[index], length: length, }
                                                                                setFormData({ ...formData, addShipmentDetailList: list });
                                                                            }}
                                                                            onFocus={(e) => e.target.select()}
                                                                        />
                                                                    </div>
                                                                </td>

                                                                <td >
                                                                    <div className="input-group">
                                                                        <input
                                                                            placeholder='Breadth'
                                                                            className="form-control"
                                                                            value={item.breath}
                                                                            maxLength='10'
                                                                            onBlur={(e) => {
                                                                                let list = [...formData.addShipmentDetailList];
                                                                                let breath = parseFloat(item.breath || 0).toFixed(3);
                                                                                list[index] = { ...list[index], breath: breath };
                                                                                setFormData({ ...formData, addShipmentDetailList: list });
                                                                            }}
                                                                            id={`txtBreadth${index}`}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    setFocus(`txtHeight${index}`);
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                let breath = e.target.value;
                                                                                breath = convertThreeDecimalsOnValue(breath);
                                                                                let list = [...formData.addShipmentDetailList];
                                                                                list[index] = { ...list[index], breath: breath, }
                                                                                setFormData({ ...formData, addShipmentDetailList: list });
                                                                            }}
                                                                            onFocus={(e) => e.target.select()}
                                                                        />
                                                                    </div>
                                                                </td>

                                                                <td >
                                                                    <div className="input-group">
                                                                        <input
                                                                            placeholder='Height'
                                                                            className="form-control"
                                                                            value={item.hight}
                                                                            maxLength='10'
                                                                            onBlur={(e) => {
                                                                                let list = [...formData.addShipmentDetailList];
                                                                                let hight = parseFloat(item.hight || 0).toFixed(3);
                                                                                list[index] = { ...list[index], hight: hight };
                                                                                setFormData({ ...formData, addShipmentDetailList: list });
                                                                            }}
                                                                            id={`txtHeight${index}`}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
                                                                                    e.preventDefault();
                                                                                    const nextIndex = index + 1;
                                                                                    if (formData.addShipmentDetailList[nextIndex]) {
                                                                                        setFocus(`txtweightUnit${nextIndex}`);
                                                                                    }
                                                                                    else {
                                                                                        setFocus(`txtSpecialInstruction`);
                                                                                    }
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                let hight = e.target.value;
                                                                                hight = convertThreeDecimalsOnValue(hight);
                                                                                let list = [...formData.addShipmentDetailList];
                                                                                list[index] = { ...list[index], hight: hight, }
                                                                                setFormData({ ...formData, addShipmentDetailList: list });
                                                                            }}
                                                                            onFocus={(e) => e.target.select()}
                                                                        />
                                                                    </div>
                                                                </td>
                                                              
                                                                <td className='' style={{ minWidth: "10px" }} >
                                                                    {formData.addShipmentDetailList.length - 1 === index && <span style={{ marginLeft: "-2px" }} >
                                                                        <i className="fa-solid fa-circle-plus text-success me-2 "
                                                                            onClick={() => handleAdd(index)}
                                                                            style={{ marginLeft: "10px", marginTop: "10px", paddingRight: "0px" }}
                                                                        />
                                                                    </span>}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>


                                                </table>
                                            </div>
                                        </div>
                                    }

                                </div>

                                <div className='row mt-3'>
                                 
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-12 mb-4">
                                        <label style={{ padding: '2px 14px' }}>Special Instruction</label>

                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                id={'txtSpecialInstruction'}
                                                type="text"
                                                placeholder="Special Instruction"
                                                value={formData.specialInstruction}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, specialInstruction: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSubmit()
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    
                                    <div className="col-xl-3 col-lg-6 col-md-12 col-12 ">
                                        <label style={{ padding: '2px 14px' }}>File Upload</label>
                                        <FileUploader
                                            classes="file-uploader emp-photo"
                                            multiple
                                            types={fileTypes.split(",")}
                                            handleChange={(files) => handleFileUploader(files)}

                                        />
                                    </div>
                                
                                    {addFilesList.length > 0 && (
                                        <div className="col-xl-9 col-lg-12 col-md-12 col-12 mb-5 mt-0">
                                            <div className='card-body p-2'>
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped mb-0">
                                                        <thead>
                                                            <tr className='table_heading tableheader'>
                                                                <th style={{ padding: '5px 4px', width: "0rem" }}>File Name</th>
                                                                <th style={{ padding: '5px 4px', width: "0rem" }}>File Extension</th>
                                                                <th style={{ padding: '5px 4px', width: "0rem" }}>Show File</th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {addFilesList.map((file, index) => (
                                                                <tr key={index}>
                                                                    <td>{file.ImageName}</td>
                                                                    <td>{file.ImageExt}</td>
                                                                    <td onClick={() => handleOpen(file.Base64Image, file.ImageExt)}> <i class="fa-solid fa-eye"></i> </td>
                                                                </tr>
                                                            ))}

                                                        </tbody>


                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    )}


                                </div>


                                <div className="col-lg-12 col-12">
                                    {isLoading ? <>
                                        <button type="button" className="btn header-button pincode" id={'btnGet'}>
                                            <div className='d-flex justify-content-center align-items-center'> <span >Loading</span>
                                                <div class="custom-loader mt-2"></div>
                                            </div>
                                        </button>
                                    </> : <>
                                            <button type="button" className="btn header-button pincode" id={'btnGet'} onClick={handleSubmit}>Submit</button>
                                    </>}
                                    
                                    <button type="button" className="btn header-button pincode mx-3"  onClick={handleClear}>Reset</button>
                                </div>

                            </form>
                        </div>

                    </div>


                </div>
            </div>



            < Modal
                show={incomeModalShow} onHide={handleModalShowClose}
                size="lg"

                keyboard={false}
                contentClassName='modaldashboard'

            >
                <Modal.Header closeButton >
                    <Modal.Title >

                        <h4>Upload Invoice View</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-md-3 mt-3 row' style={{ width: "auto", position: "relative" }}>
                        {basefile && extension !== 'pdf' ? (
                            <div className='fullscreen-image-container'>
                            <img
                                className='border border-success img-thumbnail'
                                src={`data:image/${extension};base64,${basefile}`}
                                alt='logo'
                                style={{ width: '100%', height: '400px', border: 'none' }}
                                className='fullscreen-image'
                                />
                            </div>
                        ) : basefile && extension === 'pdf' ? (
                            <iframe
                                title="PDF Preview"
                                    src={generatePdfUrl(basefile)}
                                style={{ width: '100%', height: '500px', border: 'none' }}
                            />
                        ) : null}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal >



        </section>

        </>
    )
}


export default CouriorPickUpRequest;