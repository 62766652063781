const CurrentActivities = () => {
    return (
        <>
            <section className="our-activities">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="activities-content">
                                <div className="sec-heading">
                                    <h3>
                                        Our Current <span>Activities</span>
                                    </h3>
                                </div>
                                <div className="activities-pointer mt-3">
                                    <ul>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>
                                                Transportation and delivery of normal, express Cargo and
                                                high value shipments by Air, water and surface mode &ndash;
                                                Road & Rail through out India.
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>
                                                We connect international shipments through various
                                                international vendors/service providers.
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>
                                                Warehousing Services to our customers through both
                                                dedicated and shared facilities.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="activities-img">
                                <img
                                    src="images/Current-Activities.png"
                                    className="img-fluid text-right"
                                    alt="Our Current Activities"
                                    title="Our Current Activities"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 align-items-center">
                        <div className="col-lg-6">
                            <div className="activities-img">
                                <img
                                    src="images/Can-Offer.png"
                                    className="img-fluid text-left"
                                    alt="What We Can Offer"
                                    title="What We Can Offer"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="activities-content">
                                <div className="sec-heading">
                                    <h3>
                                        What We Can <span>Offer</span>
                                    </h3>
                                </div>
                                <div className="activities-pointer mt-3">
                                    <ul>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>
                                                Duty Payments, Custom clearance & logistics services.
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>
                                                Door deliveries of express shipments and parcels by Air
                                                / Water / Rail / Surface as per your requirement.
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>
                                                Mass mailing of light envelopes
                                                /periodicals/magazines/gift articles or other shipments.
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>
                                            <span>
                                                Clearance of shipment that you could get from other
                                                service provider.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section></>
    )
}

export default CurrentActivities;