import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    focusedElementId: null,
    focusedTrackShipment: null,
    focusedservices: null,
};

export const commonSlice = createSlice({
    name: "commonSlice",
    initialState,
    reducers: {
        setContactUsFocus: (state, action) => {
            state.focusedElementId = action.payload
        }, 
        resetContactUsFocus: (state, action) => {
            state.focusedElementId = null
        },
        setTrackShipmentFocus: (state, action) => {
            state.focusedTrackShipment = action.payload
        }, 
        resetTrackShipmentFocus: (state, action) => {
            state.focusedTrackShipment = null
        },
        setServicesFocus: (state, action) => {
            state.focusedservices = action.payload
        }, 
        resetServicesFocus: (state, action) => {
            state.focusedservices = null
        },
    }
});

export const { setContactUsFocus, resetContactUsFocus, setTrackShipmentFocus, resetTrackShipmentFocus, setServicesFocus, resetServicesFocus } = commonSlice.actions;

export default commonSlice.reducer;
